// import { Fancybox } from "@fancyapps/ui";
import { gsap, Sine, Power0, Power1, Power2, Power3, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import Swiper from 'swiper/bundle';

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger);


// matchMedia
// =======================================================
// const mediaQuery_lg = window.matchMedia("(min-width: 1100px)");
// const mediaQuery_xl = window.matchMedia("(min-width: 1300px)");
const mediaQuery_md = window.matchMedia("(min-width: 768px)");


// FUNCTION
// =======================================================
function isInPage(node) {
  return (node === document.body) ? false : document.body.contains(node);
}

function closest(node, selector) {
  // IEや一部ブラウザでは.closet()が実装されていないので自前も用意
  return (
    node.closest ||
    function (_selector) {
      do {
        // nodeとselectorがマッチしたら返す
        if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
          return node;
        }
        // マッチしなかったら親要素を代入
        node = node.parentElement || node.parentNode;
      } while (node !== null && node.nodeType === 1);
      return null;
    }
  ).call(node, selector);
}

// DRAWER
// =======================================================

const menuBtn = document.querySelector('.l-drawer-btn');
const drawer = document.querySelector('.l-drawer');
const drawerLinks = document.querySelectorAll('.l-drawer a');

var tlDrawer = gsap.timeline({
  paused: true,
  onReverseComplete: function () {
    menuBtn.classList.remove("is-open");
  },
});

tlDrawer
  .to( ".l-drawer",{
    duration: 0.2, autoAlpha: 1, pointerEvents: "auto", ease: Power4.ease
  });

tlDrawer.reverse();

menuBtn.addEventListener('click', function (e) {
  e.preventDefault();
  menuBtn.classList.add("is-open");
  tlDrawer.reversed(!tlDrawer.reversed());
});

drawerLinks.forEach( link => {
  link.addEventListener('click', function (e) {
    tlDrawer.reversed(!tlDrawer.reversed());
  });
}); 

// ANCHOR
// =======================================================

document.addEventListener("DOMContentLoaded", () => {
  if (location.hash) {
    const headerHeight = document.querySelector(".l-header").clientHeight;
    setTimeout(() => {
      // window.scrollBy(0, - headerHeight*2);
    },100)
  }
})

function findHashtags(searchText) {
  const regexp = /\B#\w\w+\b/g;
  const result = searchText.match(regexp);
  if (result) {
    return result;
  } else {
    return [];
  }
}

const h = document.querySelector('.l-header');

gsap.utils.toArray('a[href*="#"]').forEach(function(a) {
  a.addEventListener("click", function(e) {

    const href = e.target.getAttribute("href");
    var currentPage = location.href.split('#')[0];
    var path = new URL(href, currentPage);
    var nextPage = path.href.split('#')[0];

    if(currentPage == nextPage ) {
      const hashTag = findHashtags(e.target.getAttribute("href"));
      const targetElm = document.querySelector(hashTag[0]);
  
      if(isInPage(targetElm)){
        e.preventDefault();
        gsap.to( window, {
          duration: 1,
          ease: 'power4.out',
          scrollTo: {
            y: targetElm,
            autoKill: false,
            // offsetY: h.offsetHeight*2, //ヘッダーの高さをセット
          }
        });
      }
    }

  });
});


// Splide
// =======================================================
import Splide from '@splidejs/splide';

const heroAboutSlider = document.querySelector(".heroAbout-slider");
if(isInPage(heroAboutSlider)) {
  const heroAboutOptions = {
    type   : 'fade',
    rewind : true,
    speed  : 2000,
    autoplay : true,
    arrows : false,
    interval: 5000,
    pagination: false,
    pauseOnHover : false,
    // mediaQuery: 'min',
    // perPage: 1,
    // gap: 24,
  }
  const heroSlider = new Splide(heroAboutSlider, heroAboutOptions);
   
  heroSlider.mount();
  
}

const worksSliderElem = document.querySelector(".works-slider");
if(isInPage(worksSliderElem)) {
  let current;
  const worksOptions = {
    type   : 'fade',
    rewind : true,
    speed  : 2000,
    autoplay : true,
    arrows : true,
    interval: 5000,
    pagination: false,
    // pauseOnHover : false,
    // mediaQuery: 'min',
    // perPage: 1,
    // gap: 24,
  }
  const worksSlider = new Splide(worksSliderElem, worksOptions);
  
  const worksSliderBtns = document.querySelectorAll("[data-index]");
  worksSliderBtns.forEach( btn => {
    btn.addEventListener("click", function(e){
      
      worksSliderBtns.forEach( toggle => {
        toggle.classList.remove("is-active");
      });
  
      const mediaQuery = window.matchMedia('(min-width: 768px)')
      if (mediaQuery.matches) {
        e.preventDefault();
        const index = parseInt(this.dataset.index);
        worksSlider.go(index);
        this.classList.add("is-active");
      }
      else {
      }
    })
  });
  
  worksSlider.on('mounted move', () => {
    if (current) {
      current.classList.remove('is-active');
    }
  
    const thumbnail = worksSliderBtns[worksSlider.index];
    if (thumbnail) {
      thumbnail.classList.add('is-active');
      current = thumbnail;
    }
  });
  
  worksSlider.mount();
  
}

// Fancybox
// =======================================================

// Fancybox.bind("[data-fancybox]", {
//   hideScrollbar: true,
//   Thumbs: {
//     type: "classic",
//   },  
//   Toolbar: {
//     display: {
//       left: [],
//       middle: [],
//       right: ["slideshow","fullscreen", "thumbs", "close"],
//     }
//   }  
// });


// Modal
// =======================================================

const modalTriggers = document.querySelectorAll("[data-trigger]");
const modalCloseBtn = document.querySelectorAll("[data-close]");
const modalJumpBtn = document.querySelectorAll("[data-jump]");

const modals = document.querySelectorAll("[data-modal]");
const modalContents = document.querySelectorAll("[data-modal-content]");

// gsap.set(modals, { opacity: 0 });
// gsap.set(modalContent, { opacity: 0, y: posY });

let delayTime = 0;
let posY = 40;

gsap.set(modals, { opacity: 0 });
gsap.set(modalContents, { opacity: 0, y: posY });

modalTriggers.forEach( trigger => {
  trigger.addEventListener('click', function(e){
    e.preventDefault();
    var target = this.dataset.trigger;
    modalOpen(target);
    
    document.body.classList.add("no-scroll");
  })
});

modalCloseBtn.forEach( btn => {
  btn.addEventListener('click', function(e){
    e.preventDefault();
    var target = closest(e.currentTarget, "[data-modal]");
    modalClose(target);
  })
});

modals.forEach( modal => {
  modal.addEventListener('click', function(e){
    if(event.target.closest('.c-interviewContent') === null) {
      var target = closest(e.currentTarget, "[data-modal]");
      modalClose(target);
    }
  })
});

modalJumpBtn.forEach( jump => {
  jump.addEventListener('click', function(e){
    var target = this.dataset.jump;
    gsap.set(modals, { opacity: 0 });
    gsap.set(modalContents, { opacity: 0, y: posY });
    modalOpen(target);
  })
});

function modalOpen(target) {
  modals.forEach( elm => {
    elm.classList.remove("is-open");
  });
  
  var modal = document.getElementById(target);
  var modalContent = modal.querySelector("[data-modal-content]");

  gsap.to(modal, {
    duration: 0,
    scrollTo: {
      y: 0,
      autoKill: false,
    }
  });
  gsap.to(modal, {
    opacity: 1,
    duration: 0.4,
    pointerEvents: "auto",
    ease: 'Sine.easeOut'
  });
  gsap.to(modalContent, {
    opacity: 1,
    y: 0,
    delay: delayTime,
    duration: 0.5,
    ease: Sine.easeOut,
    onComplete: function () {
      // movie.play();
    },
  });
  modal.classList.add("is-open");
  document.body.classList.add("no-scroll");
}

function modalClose(target) {

  var modal = target;
  var modalContent = modal.querySelector("[data-modal-content]");
  gsap.to(modalContent, {
    opacity: 0,
    y: posY,
    duration: delayTime,
    ease: Sine.easeOut,
    onComplete: function () {
    },
  });
  gsap.to(modal, {
    opacity: 0,
    duration: 0.4,
    delay: delayTime,
    pointerEvents: "none",
    onComplete: function() {
      modal.classList.remove("is-open");
    },
    ease: Sine.easeOut,
  });
  document.body.classList.remove("no-scroll");
}


// CoporateLink
// =======================================================
const coporateLink = document.querySelector(".c-coporateLink");
if(isInPage(coporateLink)) {
  coporateLink.addEventListener('mouseover', function() {
    document.body.classList.add("is-hover");
  });
  
  coporateLink.addEventListener('mouseleave', function() {
    document.body.classList.remove("is-hover");
  });
}

// Accordion
// =======================================================

const accordions = document.querySelectorAll("[data-ac]");
accordions.forEach( accordion => {
  const trigger = accordion.querySelector('[data-ac-trigger');
  const content = accordion.querySelector('[data-ac-content');
  trigger.addEventListener('click', function(){
    gsap.to(content, {
      height: (accordion.classList.contains("is-open")) ? 0 : "auto",
      duration: 0.25,
      ease: "power2.inOut"
    });
    trigger.classList.toggle("is-open");
    accordion.classList.toggle("is-open");
  })
});

// Scroll Up
// =======================================================

// Scroll Up
const topBtn = document.querySelector('.c-btn-top');
if( isInPage(topBtn) ) {

  topBtn.addEventListener("click", function(e){
    e.preventDefault();
    gsap.to(window, { duration: 0.3, scrollTo: { y: 0, autoKill: false}, ease: 'power2' });
  });

  const fadeInTopBtn = gsap.timeline({
    scrollTrigger: {
      trigger: 'body',
      start: innerHeight/2 + ' top',
      onEnter: () => {
        gsap.to(topBtn, { autoAlpha: 1, duration: 0.4, ease: Sine.easeOut });
      },
      onLeaveBack: () => {
        gsap.to(topBtn, { autoAlpha: 0, duration: 0.4, ease: Sine.easeOut });
      },
    }
  });
}


// ANIMATION
// =======================================================

// const clipElements = document.querySelectorAll("[data-clip]");
// clipElements.forEach( elm => {
//   elm.innerHTML = '<span class="clip" style="display:inline-block;position:relative;"><span class="clip-fill" style="background:#000; display:inline-block; width:100%;clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);height:100%;position:absolute;top:0;left: 0;"></span><span class="clip-text" style="opacity:0;">' + elm.textContent + '</span></span>';
// });

// const clipImgElements = document.querySelectorAll("[data-clipimg]");
// clipImgElements.forEach( elm => {
//   // 0 0, 100% 0, 100% 100%, 0% 100%
//   elm.innerHTML = '<span class="clip" style="display:inline-block;width:100%;heigth:100%;clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);">' + elm.innerHTML + '</span>';
// });


// HERO - TOP
// =======================================================


window.addEventListener('load', function(){
  const heroTop = document.querySelector(".c-heroTop");
  if( isInPage(heroTop) ) {
    const tlHeroTop = gsap.timeline();
    tlHeroTop.pause();

    const heroTopName = heroTop.querySelector(".u-nameW");
    const heroTopItem = heroTop.querySelector(".prd");
    const heroTopCopy = heroTop.querySelector(".copy-box");
    const heroTopLink_kinki = heroTop.querySelector(".float-link__kinki");
    const heroTopLink_seiwa = heroTop.querySelector(".float-link__seiwa");

    tlHeroTop
    .to(heroTopItem, { duration: 1, opacity: 1 , filter: "blur(0px)", ease: Sine.easeOut } )
    .to([heroTopName, heroTopCopy], { duration: 1, opacity: 1 , filter: "blur(0px)", ease: Sine.easeOut }, "-=0.3" )
    .to([heroTopLink_kinki, heroTopLink_seiwa], { duration: 1.5, x: "0%", ease: "none" }, "-=0.8" )

    tlHeroTop.play();
  }


  const heroAbout = document.querySelector(".c-heroAbout");
  if( isInPage(heroAbout) ) {
    const tlHeroAbout = gsap.timeline();
    tlHeroAbout.pause();

    const heroAboutNameEn = heroAbout.querySelector(".ttlBox--en");
    const heroAboutNameSince = heroAbout.querySelector(".ttlBox--since");
    const heroAboutCopy = heroAbout.querySelector(".c-heroAbout--content h2");
    const heroAboutTitle = heroAbout.querySelector(".ttlBox--ttl");
    const heroAboutImg = heroAbout.querySelector(".heroAbout-slider");

    tlHeroAbout
    .to(heroAboutImg, { duration: 1.2, scale: 1, ease: "power2.out" } )
    .to(heroAboutCopy, { duration: 2, autoAlpha: 1, ease: "power2.out" }, "-=1" )
    .to([heroAboutNameEn, heroAboutNameSince], { duration: 0.8, opacity: 1 , filter: "blur(0px)", ease: Sine.easeOut }, "-=2" )
    .to(heroAboutTitle, { duration: 0.8, autoAlpha: 1 , ease: Sine.easeOut }, "-=1" )
    tlHeroAbout.play();
  }

  const heroRec = document.querySelector(".c-heroRec");
  if( isInPage(heroRec) ) {
    const tlHeroRec = gsap.timeline();
    tlHeroRec.pause();

    const heroRecTombo = heroRec.querySelector(".u-tombo");
    const heroRecCopy = heroRec.querySelector(".recruitBox--mask");
    const heroRecImg = heroRec.querySelector(".c-heroRec__img");
    const heroRecText = heroRec.querySelector(".c-heroRec__box-text");
    const heroRecTextEn = heroRec.querySelector(".c-heroRec__copy-en");
    const heroRecBanner = heroRec.querySelector(".c-heroRec__banner");
    

    tlHeroRec
    .to(heroRecTombo, { duration: 0.5, y: "0%", opacity: 1, ease: "power2.out" } )
    .to(heroRecCopy, { duration: 1, y: "0%", opacity: 1, ease: "power2.out" }, "-=0.3" )
    .to(heroRecImg, { duration: 1, y: "0%", opacity: 1, ease: "power2.out" }, "-=1" )
    .to(heroRecText, { duration: 2, opacity: 1 , filter: "blur(0px)", ease: Sine.easeOut }, "-=2" )
    .to(heroRecBanner, { duration: 1, opacity: 1, ease: "power2.out" }, "-=1" )
    .to(heroRecTextEn, { duration: 1, opacity: 1, ease: "power2.out" }, "-=1" )

    tlHeroRec.play();
  }
});

// MAIL
// =======================================================

const mail = document.querySelectorAll('[data-mail]');

function converter(M){
  var str="", str_as="";
  for(var i=0;i<M.length;i++){
    str_as = M.charCodeAt(i);
    str += String.fromCharCode(str_as + 1);
  }
  return str;
}
var ad = converter(String.fromCharCode(114,110,116,108,116,63,114,100,104,118)+String.fromCharCode(96,114,100,104,103,96,109,45,98,110,45,105,111));

mail.forEach( element => {
  element.href = "mailto:" + ad;
  element.textContent = ad;
});

// SCROLL TRIGGER
// =======================================================


const fadeInAnimElements = document.querySelectorAll('[data-anim="fadeIn"]');
fadeInAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.7,
    autoAlpha: 1,
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    }
  })  
});

const zoomInAnimElements = document.querySelectorAll('[data-anim="zoomIn"]');
zoomInAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 1,
    scale: 1,
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    }
  })  
});

const zoomInBlurAnimElements = document.querySelectorAll('[data-anim="zoomInBlur"]');
zoomInBlurAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.7,
    scale: 1,
    filter: "blur(0px)",
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 75%",
      end: "bottom bottom",
    }
  })  
});

const blurAnimElements = document.querySelectorAll('[data-anim="blur"]');
blurAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.7,
    filter: "blur(0px)",
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 60%",
      end: "bottom bottom",
    }
  })  
});


const fadeInRightAnimElements = document.querySelectorAll('[data-anim="fadeInRight"]');

fadeInRightAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 1,
    autoAlpha: 1,
    x: 0,
    ease: "power1.out",
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "bottom bottom",
    }
  })  
});

const fadeInUpAnimElements = document.querySelectorAll('[data-anim="fadeInUp"]');

fadeInUpAnimElements.forEach( element => {
  gsap.to( element, {
    duration: 0.5,
    autoAlpha: 1,
    y: 0,
    ease: Sine.easeInOut,
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "bottom bottom",
    }
  })  
});


gsap.to( ".culture-lists .list", {
  duration: 0.4,
  autoAlpha: 1,
  y: 0,
  stagger: {
    amount: 0.8
  },
  ease: Sine.easeInOut,
  scrollTrigger: {
    trigger: ".culture-lists",
    start: "top 75%",
    end: "bottom bottom",
  }
});

gsap.to( ".welfare-lists .list", {
  duration: 0.6,
  autoAlpha: 1,
  stagger: {
    amount: 0.8
  },
  ease: Sine.easeInOut,
  scrollTrigger: {
    trigger: ".welfare-lists",
    start: "top 75%",
    end: "bottom bottom",
  }
});
